import { useLocation } from "@reach/router"
import { Formik } from "formik"
import queryString from "query-string"
import React, { useState } from "react"
import SearchField from "../components/Forms/SearchField"
import SearchGrid from "../components/Grid/SearchGrid"
import Container from "../components/Layout/Container"
import MainContent from "../components/Layout/MainContent"
import "./styles/Search.scss"

function Search() {
  const location = useLocation()
  const { query } = queryString.parse(location.search)
  const [searchQuery, setSearchQuery] = useState(query)
  const header = <div className="search__header"></div>

  return (
    <Container page="Zoeken">
      <MainContent headerComponent={header} _className="search">
        <Formik
          initialValues={{ search: query }}
          onSubmit={(values, { setSubmitting }) => {
            setSearchQuery(values.search)
            setSubmitting(false)
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form className="form" onSubmit={handleSubmit}>
              <SearchField name="search" />
            </form>
          )}
        </Formik>
        <div className="search__results">
          <SearchGrid query={searchQuery} />
        </div>
      </MainContent>
    </Container>
  )
}

export default Search
