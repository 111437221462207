import React from "react"
import { Link } from "gatsby"
import "./Result.scss"
import {
  convertToSlug,
  getSingleImage,
  getShortText,
  getTitle,
} from "../../helpers/Publications"
import { getImageType } from "../../helpers/Prepr"

export default function SearchResult({ data }) {
  return (
    <Link
      to={convertToSlug(data.model_id, data.slug)}
      className="search--result"
    >
      <div className="search--result__visual">
        <img
          src={getSingleImage(
            getImageType(
              "image_website_block",
              data.image,
              null,
              data.image_website_block,
              null
            )
          )}
          alt={getTitle(data.title)}
        />
      </div>
      <div className="search--result__content">
        <h3>{getTitle(data.title)}</h3>
        <p>{getShortText(data.shorttext)}</p>
      </div>
    </Link>
  )
}
