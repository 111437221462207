import React, { useEffect, useState } from "react"
import SearchResult from "../Search/Result"
import { useGuestApi } from "../../hooks/useApi"
import Loader from "../Loader/Loader"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 25,
}

export default function SearchGrid({ history, query }) {
  const [pagination] = useState(initialPagination)
  const { searchPublications } = useGuestApi()
  const [searchItems, setSearchItems] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    searchAllPublications()
  }, [query])

  function searchAllPublications() {
    setLoading(true)
    searchPublications(query, pagination).then(response => {
      setSearchItems(response.grid.items)
      setLoading(false)
    })
  }

  return (
    <div className="search__results">
      {loading ? (
        <div className="search__results--loading">
          <Loader color={"#FFA41F"} />
        </div>
      ) : (
        <p className="search__results--count">
          {searchItems.length} resultaten gevonden voor{" "}
          <strong>"{query}"</strong>
        </p>
      )}
      {!loading &&
        searchItems.map((result, index) => (
          <SearchResult data={result} key={index} />
        ))}
    </div>
  )
}
